// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/icons/header-dropdown-arrow.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeaderDropdownMenu{padding:6px 0;background:#fff;box-shadow:0 1px 1px rgba(11,33,65,.02),0 6px 120px rgba(11,33,65,.1);border-radius:8px}.HeaderDropdownMenu:before{content:\"\";background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-repeat:no-repeat;position:absolute;bottom:calc(100% - 1px);right:40px;width:32px;height:6px}@media(max-width:768px){.HeaderDropdownMenu{position:relative;margin-top:6px;margin-bottom:12px}}@media(min-width:769px){.HeaderDropdownMenu{min-width:288px;position:absolute;top:calc(100% + 4px);right:0;z-index:10}}.HeaderDropdownMenu__item-rating{height:58px;align-items:flex-start;padding-top:10px}.HeaderDropdownMenu__item-rating .ProfileRatingShort{position:absolute;top:100%;left:0}.HeaderDropdownMenu .HeaderDropdownMenu__divider{margin:6px 16px;border-bottom:1px solid rgba(23,35,53,.04)}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;

<template>
    <div class="modal-large" v-if="active || open">
        <div class="container">
            <large-modal-header @close="close"/>
            <div class="title">
                <slot name="title" :data="dataModal">
                    {{ title }}
                </slot>
            </div>
            <div class="modal-body">
                <slot :data="dataModal"></slot>
            </div>
        </div>
    </div>
</template>

<script>

import modal from "@/mixins/modal/modal";
import LargeModalHeader from "~/components/_layout/LargeModalHeader";

export default {
    name: "modalLarge",
    components: {LargeModalHeader},
    mixins: [modal],
    props: {
        open: Boolean,
    },
    methods: {
        close(){
            if(this.open) {
                this.$emit('close');
            }
            else {
                this.closeModal();
            }
        }
    }
}
</script>

<style lang="scss">
    .modal-large {
        position: fixed;
        z-index: 99;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #FFFFFF;
        @include safeArea;

        @include mobileUp {
            bottom: unset;
            min-height: 100vh;
            max-height: 100vh;
            overflow: auto;
        }

        .title {
            font-weight: 800;
            font-size: 24px;
            line-height: 32px;
            width: 100%;
            text-align: left;
            margin-bottom: 6px;

            @include mobileUp {
                font-size: 48px;
                line-height: 64px;
                max-width: 770px;
                margin: 42px auto 40px;
            }
        }

        .modal-body {
            padding-bottom: 40px;

            @include mobileUp {
                max-width: 770px;
                margin: 0 auto;
                padding-bottom: 80px;
            }

            @include mobile {
                max-height: calc(100vh - 64px);
                overflow: auto;
                padding-bottom: 80px; //Safari
            }
        }
    }
</style>

export default {
    computed: {
        profileExpiringBonusPoints() {
            if (this.$auth.loggedIn) {
                return this.$auth.user?.expiring_bonus_points || [];
            }
            return [];
        },
    }
}

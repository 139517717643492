<template>
    <footer :class="{'short-view': shortView}">
        <div class="container" v-if="!shortView">
            <div class="content">
                <img v-lazy-load src="@/assets/img/header/logo-v2.svg" class="logo hide-pc" />

                <footer-section class="first" title="Сервис">
                    <router-link
                        class="nav-link"
                        :to="{name: 'pages-alias', params: {alias: 'in-russia'}}"
                        @click.native="clickMenu"
                    >
                        RentRide в России
                    </router-link>
                    <router-link to="/blog" @click.native="clickMenu">Блог</router-link>
                    <router-link to="/news" @click.native="clickMenu">Новости</router-link>
                    <a href="/instructions/howitworks.png" target="_blank" @click="clickMenu">
                        Как это работает
                    </a>
                    <router-link
                        :to="{ name: 'sdat-city', params: { city: $store.state.city }}"
                        @click.native="clickMenu"
                    >
                        Сдать авто
                    </router-link>
                    <a href="/instructions/str2.png" target="_blank" @click="clickMenu">Страховка</a>
                    <router-link :to="{name: 'contacts'}" @click.native="clickMenu">Контакты</router-link>
                </footer-section>
                <footer-section class="last" title="Помощь">
                    <router-link class="nav-link" :to="{name: 'faq'}" @click.native="clickMenu">FAQ</router-link>
                    <router-link
                        class="nav-link"
                        v-for="{title, topic} in faqTopics"
                        :to="{name: 'faq-topic', params: {topic}}"
                        :key="title"
                        @click.native="clickMenu(title)"
                    >
                        {{ title }}
                    </router-link>
                </footer-section>

                <div class="contacts">
                    <h3>Связаться с нами</h3>

                    <a :href="phoneContactLink" class="phone" @click="$signals.footerClickPhone">
                        {{ phoneContact }}
                    </a>
                    <div class="phone-about">
                        <div>ежедневно с 9:00 до 21:00</div>
                    </div>
                    <div class="media-block">
                        <a
                            href="https://zen.yandex.ru/rentride"
                            rel="noreferrer"
                            target="_blank"
                            class="media-link zen-media-link"
                            @click="$signals.footerClickZen"
                        >
                            <span class="icon-holder" v-html="YandexZenIcon" />
                            <span class="text">
                                <span class="hide-mobile">Мы в Яндекс.Дзен!</span>
                                <span class="hide-pc">Яндекс.Дзен</span>
                            </span>
                        </a>
                        <a
                            href="https://vk.com/rentaride"
                            rel="noreferrer"
                            target="_blank"
                            class="media-link vk-media-link"
                        >
                            <span class="icon-holder" v-html="VkIcon" />
                            <span class="text">
                                <span class="hide-mobile">Мы ВКонтакте!</span>
                                <span class="hide-pc">ВКонтакте</span>
                            </span>
                        </a>
                    </div>
                </div>

                <hr class="hide-pc">

                <div class="apps">
                    <h3>Мобильное приложение</h3>

                    <div class="mobile-apps">
                        <a
                            class="app"
                            :href="links.ios"
                            target="_blank"
                            @click="$signals.clickApp('ios')"
                        >
                            <img v-lazy-load src="@/assets/img/logos/appStore.svg" alt="">
                            <span>App Store</span>
                        </a>
                        <a
                            class="app"
                            :href="links.android"
                            target="_blank"
                            @click="$signals.clickApp('android')"
                        >
                            <img v-lazy-load src="@/assets/img/logos/googlePlay.svg" alt="">
                            <span>Google Play</span>
                        </a>
                    </div>

                    <div class="partners">
                        <div class="partner">
                            <img v-lazy-load src="@/assets/img/logos/visa.svg" alt="Visa">
                        </div>
                        <div class="partner">
                            <img v-lazy-load src="@/assets/img/logos/mastercard.svg" alt="Mastercard">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright">
            <div class="container">
                <div class="copyright-inner">
                    <a class="offer" href="/docs/Oferta_RentRide.pdf" target="_blank">Оферта</a>
                    <span class="hide-pc">и</span>
                    <a class="offer" href="/docs/privacy_policy.pdf" target="_blank">Политика
                        конфиденциальности</a>
                    <div class="company">© {{ currentYear }} ООО «Рентрайд»</div>
                    <div>Мы объединили предложения частных автовладельцев по всей России</div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import FooterSection from "@/components/_layout/footer/footerSection";
import YandexZenIcon from "@/assets/img/layouts/yandex-zen.svg?raw";
import VkIcon from "@/assets/img/layouts/VK-original-icon.svg?raw";
import currentYear from '@/mixins/date/currentYear';
import phoneContacts from '@/mixins/contacts/phoneContacts';
import { APP_LINKS } from '@/utils/_consts';
import { playMarketUtmLink } from '@/utils/router';

const _faqTopic = (title, topic) => ({ title, topic });
//faq-topic

export default {
    name: "Footer",
    components: { FooterSection },
    mixins: [currentYear, phoneContacts],
    props: {
        shortView: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        YandexZenIcon,
        VkIcon,
        faqTopics: [
            _faqTopic('Взять в аренду', 'to-rent'),
            _faqTopic('Сдать в аренду', 'rent-out'),
            _faqTopic('Платежи', 'payment'),
            _faqTopic('ГИБДД', 'rules'),
            _faqTopic('Регистрация пользователя', 'user-registration'),
        ],
    }),
    computed: {
        links() {
            return {
                android: playMarketUtmLink(),
                ios: APP_LINKS.ios,
            }
        },
    },
    methods: {
        clickMenu(raw) {
            let title = raw;
            if (typeof title !== 'string') {
                title = raw?.target?.innerText;
            }
            this.$signals.footerClickNavItem(title);
        },
    },
}
</script>

<style lang="scss">
footer {
    padding-top: 32px;

    &.short-view {
        padding-top: 0;

        @include mobile {
            border-top: none;
        }
    }

    @include mobile {
        border-top: 1px solid rgba($blackBlue, 0.03);
    }

    a:hover {
        text-decoration: none;
        color: $primaryDark;
    }

    @include mobileUp {
        margin-top: 40px;
    }

    .content {
        padding-bottom: 32px;

        @include mobileUp {
            padding-bottom: 42px;
        }

        @include mobileUp {
            display: grid;
            grid-gap: 16px;
            grid-template-columns: repeat(2, 1fr);
            align-items: start;
        }

        @include laptopUp {
            display: grid;
            grid-gap: 32px;
            grid-template-columns: repeat(4, 1fr);
            align-items: start;
        }

        @include mobile {
            text-align: center;
        }
    }

    h3 {
        font-weight: bold;
        font-size: 16px;
        line-height: 28px;
        color: $blackBlue;
        margin-bottom: 10px;

        @include mobile {
            margin-top: 32px;
        }
    }

    hr {
        margin: 32px 0;
    }

    .logo {
        margin-bottom: 26px;
    }

    .contacts {
        color: $grayText;

        .phone {
            font-weight: 800;
            font-size: 24px;
            line-height: 32px;
            color: $blackBlue;
            margin-bottom: 3px;
            white-space: nowrap;
        }

        .phone-about {
            font-size: 12px;
            line-height: 16px;
        }

        .media-block {
            display: inline-grid;
            grid-gap: 12px;
            margin-top: 38px;

            @include mobile {
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 24px;
                margin-top: 32px;
            }

            .media-link {
                display: flex;
                align-items: center;

                font-weight: bold;
                font-size: 16px;
                line-height: 24px;

                transition: 250ms;

                &.zen-media-link:hover {
                    .icon-holder path {
                        fill: $primaryDark;
                    }
                }

                &.vk-media-link:hover {
                    color: #307EFD;
                }

                .icon-holder {
                    display: flex;
                    margin-right: 14px;

                    @include mobile {
                        margin-right: 12px;
                    }

                    path {
                        transition: 250ms;
                    }
                }
            }
        }
    }

    .apps {

        h3 {
            margin-bottom: 12px;
        }

        .mobile-apps {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 8px;
            margin-bottom: 32px;

            @include mobileUp {
                margin-bottom: 40px;
            }

            .app {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 48px;
                background: #FFFFFF;
                box-shadow: 0 4px 12px -6px rgba(209, 214, 224, 0.8);
                border-radius: 4px;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;

                transition: 250ms;

                &:hover {
                    color: $blackBlue;
                    box-shadow: 0 0.4px 1px rgba(7, 22, 44, 0.04), 0 16px 48px -8px rgba(11, 33, 66, 0.12);
                }

                @include mobileUp {
                    width: 160px;
                }

                img {
                    margin-right: 12px;
                }
            }
        }

        .partners {
            display: flex;

            @include mobile {
                justify-content: center;
            }

            @include mobileUp {
                margin: 0 -8px;
            }

            .partner {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 56px;
                height: 32px;
                margin: 0 8px;
                background: #EAECF0;
                border-radius: 4px;
            }
        }
    }

    .copyright {
        padding: 32px 0 24px;
        border-top: 1px solid rgba($blackBlue, 0.03);
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        background-color: #F0F1F5;
        color: #9AA5B7;

        @include mobile {
            text-align: center;
        }

        @include mobileUp {
            padding: 20px 0;

            .copyright-inner {
                display: flex;
            }
        }

        .company {
            margin-bottom: 16px;

            @include mobileUp {
                margin: 0 48px 0 0;
            }
        }

        .offer {
            display: inline-flex;
            margin-bottom: 16px;

            @include mobileUp {
                order: 2;
                margin: 0 0 0 48px;
            }
        }
    }
}
</style>

<template>
    <div
        v-if="profileExpiringBonusPoints.length"
        class="PaymentExpiringBonusPoints"
        :class="{'PaymentExpiringBonusPoints--dark': dark}"
    >
        <div
            class="PaymentExpiringBonusPoints__item"
            v-for="({expired_at, points_left}, index) in profileExpiringBonusPoints"
            :key="index"
        >{{ points_left }} баллов сгорят через <b>{{ formatExpirationTime(expired_at) }}</b></div>
    </div>
</template>

<script>

import {declensionHours, declensionMinutesCount, declensionNormalDays} from "@/utils/word";
import {NBSP} from "@/utils/_consts";
import profileExpiringBonusPoints from "@/mixins/profile/profileExpiringBonusPoints";

export default {
    name: "PaymentExpiringBonusPoints",
    mixins: [profileExpiringBonusPoints],
    props: {
        dark: Boolean,
    },
    methods: {
        formatExpirationTime(date) {
            const result = [];
            const duration = this.$moment.duration(this.$moment.unix(date).diff(this.$moment()));

            const days = duration.days();
            if (days) {
                result.push(declensionNormalDays(days));
            }

            const hours = duration.hours();
            if (hours) {
                result.push(declensionHours(hours));
            } else if (!days) {
                const minutes = duration.minutes();
                result.push(declensionMinutesCount(minutes));
            }

            return result.map((time) => time.replace(' ', NBSP)).join(', ');
        },
    },
}
</script>

<style lang="scss">
.PaymentExpiringBonusPoints {

    &--dark & {
        &__item {
            background: rgba(255, 255, 255, 0.12);
            color: $white;
        }
    }

    &__item {
        padding: 12px;

        background: #EBF2FF;
        border-radius: 12px;

        white-space: break-spaces;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.005em;
        color: $blackBlue;

        & + & {
            margin-top: 8px;
        }

        b {
            margin: 0;
            font-weight: 700;
        }
    }
}
</style>

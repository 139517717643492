<template>
    <a class="phone-number-link" :href="phoneContactLink" @click="$signals.headerClickPhone">
        <span class="phone-number">
            {{ phoneContact }}
        </span>
    </a>
</template>

<script>
import phoneContacts from '@/mixins/contacts/phoneContacts';

export default {
    name: "HeaderPhoneNumber",
    mixins: [phoneContacts],
}
</script>

<style lang="scss">
.phone-number-link {
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;

    @include mobileUp {
        margin-bottom: 0;
    }

    &:hover {
        text-decoration: none;
    }

    .phone-number {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: $blackBlue;
        white-space: nowrap;
    }
}
</style>

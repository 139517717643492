<template>
    <RouterLink
        class="HeaderDropdownMenuItem"
        :to="to"
    >
        <span class="HeaderDropdownMenuItem__icon-holder" v-html="icon" />
        <div class="HeaderDropdownMenuItem__container">
            <div class="HeaderDropdownMenuItem__body">
                <span>{{ title }}</span>
                <NotificationCounter :count="notifications"/>
            </div>
            <slot/>
        </div>
    </RouterLink>
</template>

<script>
import NotificationCounter from "@/components/_elements/NotificationCounter";

export default {
    name: "HeaderDropdownMenuItem",
    components: {NotificationCounter},
    props: {
        title: String,
        to: [Object, String],
        notifications: Number,
        icon: String,
    },
    methods: {
        click() {
            this.$emit('click', this.title);
        },
    },
}
</script>

<style lang="scss">
.HeaderDropdownMenuItem {
    height: 44px;
    padding: 0 16px;

    display: flex;
    align-items: center;

    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $blackBlue;
    white-space: nowrap;

    cursor: pointer;
    transition: background-color 250ms;

    &:hover {
        text-decoration: none;
        background-color: #F0F6FF;

        .HeaderDropdownMenuItem__icon-holder {
            fill: $primary;
        }
    }

    &__container {
        position: relative;
        display: flex;
        flex-grow: 1;
    }

    &__body {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    &__icon-holder {
        margin-right: 12px;
        display: flex;

        path {
            fill: #C7CACF;
            fill-opacity: 1;
            transition: fill 250ms;
        }
    }
}
</style>

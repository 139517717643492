export default {
    data: () => ({
        scrolledUp: false,
        scrollFixed: false,
    }),
    computed: {
        scrollPositionOffset() {
          return 200;
        },
        scrollHandler() {
            let prevScrollPosition = 0;
            return () => {
                const scrollPosition = window.scrollY;
                this.scrollFixed = scrollPosition > this.scrollPositionOffset;
                this.scrolledUp = this.scrollFixed && scrollPosition && scrollPosition < prevScrollPosition;
                prevScrollPosition = scrollPosition;
            }
        },
    },
    created() {
        if (process.client) {
            window.addEventListener('scroll', this.scrollHandler, {passive: true});
        }
    },
    beforeDestroy() {
        if (process.client) {
            window.removeEventListener('scroll', this.scrollHandler)
        }
    }
}

<template>
    <div
        class="header-wrapper"
        :class="{'open-mobile-menu': headerHasOpenedDropdown, white, fixed: !frozen && scrollFixed, show: !frozen && scrolledUp}"
    >
        <header class="header">
            <div class="container">
                <div class="content-wrapper">
                    <div class="content">
                        <slot name="logo">
                            <router-link class="d-flex" to="/" @click.native="$signals.headerClickLogo">
                                <logo/>
                            </router-link>
                        </slot>
                        <div class="header-nav-wrapper hide-mobile">
                            <slot name="nav">
                                <header-nav
                                    :authorized="authorized"
                                    @toggle-bonus-tooltip="bonusTooltipOpen = $event"
                                />
                            </slot>
                        </div>
                        <div class="actions">
                            <div class="user-panel" @click="toggleMenu">
                                <slot name="user-panel">
                                    <div v-if="authorized" class="user-panel-avatar">
                                        <NotificationCounter :count="profileUnreadMessagesCount"/>
                                        <avatar
                                            :url="$auth.user.avatar"
                                            :user-name="$auth.user.first_name"
                                        />
                                    </div>
                                    <div v-else class="not-authorized-wrapper hide-mobile">
                                        <router-link to="/sign-in" class="login-button">
                                            <button class="gray small">Войти</button>
                                        </router-link>
                                    </div>
                                    <div class="menu-controller-button" :class="{'hide-pc': !authorized}">
                                        <img v-lazy-load
                                            v-if="!menuOpen"
                                            src="@/assets/img/icons/header-menu-open.svg"
                                            alt="open-menu"
                                            loading="lazy"
                                        >
                                        <img v-lazy-load
                                            v-else
                                            src="@/assets/img/icons/header-menu-close.svg"
                                            alt="close-menu"
                                            loading="lazy"
                                        >
                                    </div>
                                </slot>
                            </div>
                            <LocationSelect/>
                        </div>
                    </div>
                    <template v-if="menuOpen">
                        <div class="header-drop">
                            <header-dropdown-menu v-click-outside="closePcMenu" v-if="authorized"/>
                        </div>
                    </template>
                </div>
            </div>
        </header>
    </div>
</template>

<script>
import avatar from "@/components/_elements/profile/avatar";
import vClickOutside from "v-click-outside";
import HeaderNav from "~/components/_layout/header/headerNav";
import HeaderDropdownMenu from "~/components/_layout/header/HeaderDropdownMenu";
import Logo from "~/components/_elements/logo";
import blockScroll from "~/mixins/modal/blockScroll";
import scrollUpShow from "~/mixins/position/scrollUpShow";
import LocationSelect from "@/components/_layout/header/locationSelect";
import NotificationCounter from "@/components/_elements/NotificationCounter";
import { mapState } from "vuex";

export default {
    name: "Header",
    components: {
        NotificationCounter,
        LocationSelect,
        Logo,
        HeaderDropdownMenu,
        HeaderNav,
        avatar,
    },
    mixins: [blockScroll('mobileMenuOpen'), scrollUpShow],
    directives: {clickOutside: vClickOutside.directive},
    props: {
        frozen: Boolean,
        white: Boolean,
    },
    data: () => ({
        menuOpen: false,
        bonusTooltipOpen: false,
    }),
    computed: {
        authorized() {
            return this.$auth.loggedIn;
        },
        mobileMenuOpen() {
            return this.$media.md && this.menuOpen;
        },
        headerHasOpenedDropdown() {
            return this.menuOpen || this.bonusTooltipOpen;
        },
        ...mapState('profile', ['profileUnreadMessagesCount']),
    },
    methods: {
        openMenu() {
            this.menuOpen = true;
        },
        closeMenu() {
            this.menuOpen = false;
        },
        toggleMenu() {
            this.menuOpen = !this.menuOpen;

            if (this.authorized) {
                if (this.menuOpen) {
                    this.$signals.headerClickOpenMenu();
                }
            } else {
                this.$signals.headerClickSignIn();
            }
        },
        closePcMenu() {
            if (!this.$media.md) {
                this.closeMenu();
            }
        }
    },
    mounted() {
        this.closeMenu();
    },
    watch: {
        '$route': {
            deep: true,
            handler() {
                this.closeMenu();
            }
        },
        scrolledUp() {
            this.closePcMenu();
        },
        scrollFixed() {
            this.closePcMenu();
        },
    }
}
</script>

<style lang="scss">
.header-wrapper {
    position: relative;
    z-index: 201;

    &.fixed {
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;

        transform: translateY(-100%);

        .header {
            transition: 250ms;
            background-color: $white;
        }

        &.show {
            .header {
                transform: translateY(100%);
                box-shadow: 0 0.4px 2px rgba(11, 33, 65, 0.04), 0 10px 32px -4px rgba(11, 33, 65, 0.04);
            }
        }
    }

    &.white {
        .header {
            position: relative;
            background-color: $white;
        }
    }

    &.open-mobile-menu {
        z-index: 250;
    }

    @include mobile {
        &.open-mobile-menu {
            &, .header {
                transition: none !important;
                transform: translateY(0) !important;
            }

            .header {
                height: 100%;
                padding-bottom: 32px;
            }

            z-index: 500;
            position: fixed;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;

            overflow-y: auto;
            background-color: $white;
            @include safeArea;
        }
    }
}

.header {
    .content-wrapper {
        position: relative;
    }

    .content {
        height: 50px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        @include mobileUp {
            height: auto;
            min-height: 60px;

            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .header-nav-wrapper {
            width: 100%;
        }

        .actions {

            .login-button {

                button {
                    padding: 0 16px;
                }

                &:hover {
                    text-decoration: none;
                }
            }

            .location-select-block {
                @include mobileUp {
                    display: none;
                }
            }

            .user-panel {
                display: flex;
                align-items: center;
                cursor: pointer;

                @include mobile {
                    display: none;
                }

                .user-panel-avatar {
                    position: relative;

                    .notification-counter {
                        position: absolute;
                        top: -2px;
                        right: -8px;
                    }
                }

                .profile-avatar {
                    width: 36px;
                    height: 36px;
                    transition: 250ms;

                    @include mobileUp {
                        height: 40px;
                        width: 40px;
                    }

                    &:hover {
                        transform: scale(1.1);
                    }
                }

                .menu-controller-button {
                    margin-left: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }

}
</style>

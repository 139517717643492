<template>
    <div v-show="count" class="notification-counter" :style="style">
        {{ count }}
    </div>
</template>

<script>
export default {
    name: "NotificationCounter",
    props: {
        count: {
            type: Number,
            default: 0,
        },
        marginLeft: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        style() {
            if (this.marginLeft) {
                return {
                    marginLeft: `${this.marginLeft}px`
                };
            }
        },
    },
}
</script>

<style lang="scss">
.notification-counter {
    display: inline-flex;
    justify-content: center;
    flex-shrink: 0;
    z-index: 2;

    min-width: 20px;
    padding: 0 6px;

    border-radius: 10px;
    background-color: #F61F2B;

    font-weight: bold;
    font-size: 10px;
    line-height: 20px;
    color: $white;
}
</style>

<template>
    <div class="large-modal-header">
        <logo/>
        <div class="close-action" @click="close">
            <div class="close-text">Закрыть</div>
            <div class="close-button" v-html="CloseIcon"/>
        </div>
    </div>
</template>

<script>
import CloseIcon from "@/assets/img/icons/header-menu-close.svg?raw"
import Logo from "~/components/_elements/logo";

export default {
    name: "LargeModalHeader",
    components: {Logo},
    data: () => ({
        CloseIcon
    }),
    methods: {
        close() {
            this.$emit('close');
        }
    },
}
</script>

<style lang="scss">
.large-modal-header {
    height: 56px;

    @include tabletUp {
        height: 60px;
    }

    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .close-action {
        display: flex;
        align-items: center;
        cursor: pointer;

        @include mobileUp {
            position: absolute;
            top: 22px;
            right: 0;
        }

        &:hover {
            opacity: 0.9;
        }

        //&:hover, &:focus {
        //    .close-text {
        //        color: rgba($blackBlue, 0.64);
        //    }
        //
        //    .close-button {
        //        background-color: #E4E9F1;
        //
        //        path {
        //            fill: #828B98;
        //        }
        //    }
        //}

        .close-text {
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            transition: 250ms;
        }

        .close-button {
            margin-left: 8px;
            display: flex;

            path {
                transition: 250ms;
            }
        }
    }
}
</style>

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/icons/arrow-black-blue.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".folding-enter-active,.folding-leave-active{transition:all .35s ease-in-out;overflow:hidden;opacity:1}.folding-enter,.folding-leave-to{opacity:0;height:0}.footer-section{text-align:left}@media(max-width:768px){.footer-section:not(.last)>.head{border-bottom:1px solid rgba(23,35,53,.04)}.footer-section.first>.head{border-radius:8px 8px 0 0}.footer-section.last:not(.open)>.head{border-radius:0 0 8px 8px}}.footer-section>.head{position:relative;transition:all .25s}@media(max-width:768px){.footer-section>.head{background:rgba(23,35,53,.03);padding:13px 18px}.footer-section>.head:after{content:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");height:24px;position:absolute;right:20px;top:calc(50% - 12px);transition:all .25s}.footer-section>.head:after path{fill:rgba(23,35,53,.16)}}@media(min-width:769px){.footer-section>.head{margin-bottom:8px}}.footer-section>.head>.title{font-weight:700;font-size:16px;line-height:28px}.footer-section>.body{transition:all .25s}@media(max-width:768px){.footer-section>.body{background:#f6f7f9}}@media(min-width:769px){.footer-section>.body{display:block!important}}.footer-section>.body>.body-wrapper a{display:block;font-size:14px;line-height:22px;transition:color .25s}.footer-section>.body>.body-wrapper a:hover{color:#007efd}@media(min-width:769px){.footer-section>.body>.body-wrapper{margin:-6px 0}.footer-section>.body>.body-wrapper a{padding:4px 0}}@media(max-width:768px){.footer-section>.body>.body-wrapper{padding:8px 0}.footer-section>.body>.body-wrapper a{padding:11px 16px}}@media(max-width:768px){.footer-section.open .head:after{transform:scaleY(-1)}.footer-section.open:not(.last)>.head{border-bottom-color:#f6f7f9}.footer-section.open>.body,.footer-section.open>.head{background:#fff}.footer-section.open+.open{border-top:1px solid rgba(23,35,53,.04)}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;

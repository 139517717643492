<template>
    <div class="header-nav">
        <div class="main-content">
            <ul class="nav-list">
                <li
                    class="nav-item"
                    v-for="({name, filters}) in catalogNavItems"
                    :key="name"
                    @click="$signals.headerClickNavItem(name)"
                >
                    <router-to-cars class="nav-link" :filters="filters">
                        {{ name }}
                    </router-to-cars>
                </li>
                <li
                    class="nav-item"
                    v-for="{title, to, source_name} in menu"
                    :key="title"
                    @click="headerClickNavItem(title, source_name)"
                >
                    <router-link class="nav-link" :to="to">{{ title }}</router-link>
                </li>
                <li class="nav-item hide-pc" v-if="!authorized" @click="$signals.headerClickSignIn">
                    <router-link class="nav-link" to="/sign-in">Войти</router-link>
                </li>
            </ul>
            <div class="other-actions">
                <header-phone-number/>
                <location-select/>
            </div>
        </div>
        <div class="other-actions-block">
            <div class="phone-number-pc">
                <header-phone-number/>
            </div>
            <div
                class="payment-bonus-block"
                :class="{'expiring-bonus': profileExpiringBonusPoints.length}"
                v-click-outside="closeBonusPointsToolTip"
                @mouseenter="openBonusPointsToolTip"
                @mouseleave="handleBonusPointsMouserLeave"
            >
                <PaymentBonusPointsCount v-if="$auth.loggedIn"/>
                <div class="payment-bonus-tooltip" v-show="showBonusPointsTooltip">
                    <b class="payment-bonus-tooltip-title">Накопленные баллы</b>
                    <p>
                        Баллы можно тратить на будущие <br>
                        поездки, 1 балл = 1 рубль.
                    </p>
                    <div class="payment-bonus-tooltip-link" @click="openModalBonusPointsInfoModal">
                        Подробнее
                    </div>
                    <PaymentExpiringBonusPoints
                        class="header-nav-expiring-bonuses"
                        dark
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LocationSelect from "~/components/_layout/header/locationSelect";
import RouterToCars from "~/components/router/routerToCars";
import HeaderPhoneNumber from "~/components/_layout/header/HeaderPhoneNumber";
import PaymentBonusPointsCount from "@/components/profile/bonus/PaymentBonusPointsCount";
import vClickOutside from "v-click-outside";
import {mapMutations} from "vuex";
import PaymentExpiringBonusPoints from "@/components/profile/bonus/PaymentExpiringBonusPoints";
import profileExpiringBonusPoints from "@/mixins/profile/profileExpiringBonusPoints";
import {SIGN_UP_SOURCE} from "@/utils/_consts";

const _menu = (title, to, source_name) => ({title, to, source_name});

let bonusPointsHoverTimer;

export default {
    name: "headerNav",
    components: {PaymentExpiringBonusPoints, PaymentBonusPointsCount, HeaderPhoneNumber, RouterToCars, LocationSelect},
    directives: {clickOutside: vClickOutside.directive},
    mixins: [profileExpiringBonusPoints],
    props: {
        authorized: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        showBonusPointsTooltip: false,
        catalogNavItems: [
            {
                name: 'Аренда авто',
                filters: {vehicle_type: 'car'},
            },
        ],
    }),
    computed: {
        menu() {
            return [
                _menu('Сдать авто', {
                    name: 'sdat-city',
                    params: {city: this.$store.state.city}
                }, SIGN_UP_SOURCE.HEADER_SDAT),
                _menu('О нас', {name: 'company'}),
                _menu('Контакты', {name: 'contacts'}),
            ];
        }
    },
    watch: {
        showBonusPointsTooltip: {
            immediate: true,
            handler(status) {
                this.$emit('toggle-bonus-tooltip', status);
            },
        },
    },
    beforeDestroy() {
        this.clearBonusPointsTimer();
    },
    methods: {
        cleanFiltersCatalog() {
            this.$store.commit('cars/cleanFilter');
        },
        openBonusPointsToolTip() {
            this.showBonusPointsTooltip = true;
        },
        closeBonusPointsToolTip() {
            this.clearBonusPointsTimer();
            this.showBonusPointsTooltip = false;
        },
        handleBonusPointsMouserLeave() {
            this.clearBonusPointsTimer();
            this.openBonusPointsToolTip();
            bonusPointsHoverTimer = setTimeout(() => {
                this.closeBonusPointsToolTip();
            }, 500);
        },
        clearBonusPointsTimer() {
            if (bonusPointsHoverTimer) {
                clearTimeout(bonusPointsHoverTimer);
            }
        },
        openModalBonusPointsInfoModal() {
            this.openModal(this.$modals.PAYMENT_BONUS_POINTS_INFO);
        },
        headerClickNavItem(title, source) {
            this.$signals.headerClickNavItem(title);
            this.setSourceSignup(source);
        },
        ...mapMutations(['setSourceSignup']),
        ...mapMutations('modal', ['openModal']),
    },
}
</script>

<style lang="scss">
.header-nav {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include mobileUp {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px 0 24px;
    }

    @include tabletUp {
        padding: 0 16px 0 32px;
    }

    @include laptopUp {
        padding: 0 16px 0 48px;
    }

    .main-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include mobileUp {
            width: auto;
            flex-direction: row;
            align-items: center;
        }

        .nav-list {
            width: 100%;
            padding: 12px 0 0;
            margin: 0;
            display: flex;
            flex-direction: column;
            list-style: none;
            flex-wrap: wrap;

            @include mobileUp {
                margin: 0 -12px;
                padding: 8px 0;
                flex-direction: row;
            }

            .nav-item {
                font-weight: 500;
                font-size: 20px;
                line-height: 28px;
                white-space: nowrap;

                @include mobileUp {
                    margin: 0 12px;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;

                    @media(max-width: 1010px) {
                        margin: 0 6px;
                    }
                }

                & + .nav-item {
                    margin-top: 8px;

                    @include mobileUp {
                        margin-top: 0;
                    }
                }

                .nav-link {
                    display: block;
                    padding: 8px 0;
                    color: $blackBlue;
                    transition: color 250ms;

                    @include mobileUp {
                        padding: 2px 0;
                    }

                    &:hover {
                        color: #307EFD;

                        text-decoration: none;
                    }
                }
            }

        }

        .location-picker {
            margin-left: 24px;
        }
    }

    .other-actions {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include mobileUp {
            margin-left: 24px;
        }

        @include mobile {
            width: 100%;
            margin-top: 16px;
            padding-top: 24px;

            display: flex;
            flex-direction: column;
            align-items: stretch;

            border-top: 1px solid rgba($blackBlue, 0.04);
        }

        .phone-number-link {
            margin-bottom: 24px;

            @include mobileUp {
                display: none;
            }
        }
    }

    .phone-number-pc {
        @include mobile {
            display: none;
        }

        @include mobileUp {
            padding-left: 16px;
        }
    }

    .other-actions-block {
        display: inline-flex;
        align-items: center;
    }

    .payment-bonus-block {
        position: relative;
        margin-left: 16px;

        z-index: 2;
        cursor: pointer;

        &.expiring-bonus {
            &::after {
                content: '';
                position: absolute;
                top: 1px;
                right: -1px;

                width: 6px;
                height: 6px;

                border-radius: 50%;
                background: #F61F2B;
            }
        }

        .payment-bonus-tooltip {
            position: absolute;
            top: 100%;
            right: 0;

            max-width: 273px;
            padding: 16px;
            margin-top: 13px;

            background: $blackBlue;
            border-radius: 12px;

            white-space: nowrap;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.005em;
            color: $white;

            cursor: default;

            .payment-bonus-tooltip-title {
                display: block;
                margin-bottom: 8px;

                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: -0.01em;
            }

            p {
                margin: 0;
            }

            .payment-bonus-tooltip-link {
                display: inline-block;
                margin-top: 1em;

                color: #307EFD;
                cursor: pointer;

                &:hover {
                    color: $primary;
                    text-decoration: none;
                }
            }

            &::before {
                position: absolute;
                bottom: 100%;
                right: 20px;
                content: '';
                width: 12px;
                height: 6px;

                background-image: url("~@/assets/img/icons/dark-tooltip-arrow-top.svg");
                background-repeat: no-repeat;
                background-position: center top;
                background-size: contain;
            }

            &::after {
                content: '';
                position: absolute;
                top: -15px;
                left: -10px;
                bottom: -10px;
                right: -10px;
                z-index: -5;
            }
        }
    }

    .header-nav-expiring-bonuses {
        margin-top: 16px;
        margin-bottom: 6px;
    }
}
</style>

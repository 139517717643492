<template>
    <div class="location-select-block">
        <div class="address-target" @click.prevent="toggle">
            <span class="hide-mobile" v-html="AddressLocationIcon"/>
            <span class="hide-pc" v-html="AddressLocationMobileIcon"/>
            <span class="address">{{ currentCity.title }}</span>
        </div>
        <modal-large class="modal-location" :open="open" @close="close" title="Выбор города" id="city" no-query>
            <template #default>
                <location-select-list class="largest" :list="listCities.largest" @close="close" />
                <location-select-list :list="listCities.other" @close="close" />
            </template>
        </modal-large>
    </div>
</template>

<script>
import AddressLocationIcon from '@/assets/img/icons/header-nav-address.svg?raw'
import AddressLocationMobileIcon from '@/assets/img/icons/header-nav-address-mobile.svg?raw'
import { mapActions, mapGetters, mapState } from "vuex";
import blockScroll from "@/mixins/modal/blockScroll";
import LocationSelectList from "@/components/_layout/header/locationSelectList";
import ModalLarge from "@/components/_elements/modalLarge";

export default {
    name: "locationSelect",
    components: {ModalLarge, LocationSelectList},
    data: () => ({
        AddressLocationIcon,
        AddressLocationMobileIcon,
        open: false
    }),
    mixins: [blockScroll('open')],
    computed: {
        ...mapGetters('glossary', ['listCities', 'currentCity']),
        ...mapState('glossary', ['_readyGlossaries', 'pending']),
    },
    methods: {
        toggle() {
            this.open = !this.open;

            if (this.open) {
                this.$signals.filterClickCityFromHeader(this.currentCity.title);
            }
        },
        close() {
            this.open = false;
        },
        ...mapActions('glossary', ['getGlossaries'])
    }
}
</script>

<style lang="scss">
.location-select-block {

    .address-target {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 6px;

        cursor: pointer;

        @include mobile {
            margin: -6px;
        }

        &:hover {
            .address {
                color: rgba($blackBlue, 0.64);
            }
            path {
                fill: rgba($blackBlue, 0.64);
            }
        }

        .address {
            display: flex;
            align-items: center;
            text-align: left;

            margin-left: 4px;

            font-weight: 400;
            font-size: 16px;
            line-height: 1;
            color: #307EFD;

            transition: all 0.25s;

            @include mobile {
                margin-left: 6px;

                font-size: 14px;
                color: $blackBlue;
            }
        }


        svg {
            display: block;

            path {
                transition: fill 250ms;
            }
        }
    }
}
</style>

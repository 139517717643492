<template>
    <div class="location-select-list">
        <div class="city" v-for="({title, alias}) in validList" :key="alias" @click="select(alias)">{{ title }}</div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
    name: "locationSelectList",
    props: {
        list: Array
    },
    computed: {
        validList() {
            return this.list.filter(({ cars_count }) => cars_count === undefined || cars_count > 0);
        },
        ...mapGetters('glossary', ['currentCity']),
    },
    methods: {
        select(city) {
            this.setCity(city);
            this.$cookies.set('city', city, { path: '/' });
            this.$emit('close');
            this.$nextTick(() => {
                this.$signals.filterSetCity(this.currentCity.title);
            });
        },
        ...mapMutations(['setCity'])
    }
}
</script>

<style lang="scss">
.location-select-list {
    text-align: left;

    @include mobileUp {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 10px 30px;
        max-width: 770px;
        margin: 0 auto;
    }

    .city {
        font-size: 14px;
        line-height: 24px;
        margin-top: 12px;
        transition: all 0.25s;

        &:hover {
            cursor: pointer;
            color: $primary;
        }

        @include mobileUp {
            margin: 0;
            padding: 10px 0;
        }
    }

    &.largest {

        @include mobileUp {
            margin-bottom: 30px;
        }

        .city {
            font-weight: bold;
            font-size: 18px;
            line-height: 30px;

            @include mobileUp {
                font-weight: 800;
                font-size: 24px;
                line-height: 32px;

                &:nth-child(2) {
                    grid-column: 2 / 4;
                }
            }
        }
    }
}
</style>

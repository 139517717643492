<template>
    <div class="footer-section" :class="{open}">
        <div class="head" @click="toggle">
            <div class="title">{{ title }}</div>
        </div>
        <transition
            name="folding"
            @enter="enter"
            @after-enter="afterEnter"
            @leave="leave">
            <div class="body" v-show="open">
                <div class="body-wrapper">
                    <slot></slot>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>

import {foldingHeightTransition} from "~/mixins/foldingHeightTransition";

export default {
    name: "footerSection",
    mixins: [foldingHeightTransition],
    props: {
        title: String
    },
    data() {
        return {
            open: true,
        };
    },
    methods: {
        toggle(){
            if(this.$media.md) {
                this.open = !this.open;
            }
        }
    }
}
</script>

<style lang="scss">

.folding-enter-active,
.folding-leave-active {
    transition: all 0.35s ease-in-out;
    overflow: hidden;
    opacity: 1;
}

.folding-enter,
.folding-leave-to {
    opacity: 0;
    height: 0;
}

.footer-section {
    text-align: left;

    @include mobile {
        &:not(.last) > .head {
            border-bottom: 1px solid rgba($blackBlue, 0.04);
        }

        &.first > .head {
            border-radius: 8px 8px 0 0;
        }

        &.last:not(.open) > .head {
            border-radius: 0 0 8px 8px;
        }
    }

    > .head {
        position: relative;
        transition: all 0.25s;

        @include mobile {
            background: rgba(23, 35, 53, 0.03);
            padding: 13px 18px;

            &::after {
                content: url("~@/assets/img/icons/arrow-black-blue.svg");
                height: 24px;
                position: absolute;
                right: 20px;
                top: calc(50% - 24px / 2);
                transition: all 0.25s;

                path {
                    fill: rgba($blackBlue, 0.16);
                }
            }
        }

        @include mobileUp {
            margin-bottom: 8px;
        }

        > .title {
            font-weight: bold;
            font-size: 16px;
            line-height: 28px;
        }
    }

    > .body {
        transition: all 0.25s;

        @include mobile {
            background: $grayBackground;
        }

        @include mobileUp {
            display: block !important;
        }

        > .body-wrapper {
            a {
                display: block;
                font-size: 14px;
                line-height: 22px;

                transition: 250ms color;

                &:hover {
                  color: $primaryDark;
                }
            }

            @include mobileUp {
                margin: -6px 0;

                a {
                    padding: 4px 0;
                }
            }

            @include mobile {
                padding: 8px 0;

                a {
                    padding: 11px 16px;
                }
            }
        }
    }

    &.open {
        @include mobile {
            .head::after {
                transform: scale(1, -1);
            }

            &:not(.last) > .head {
                border-bottom-color: #F6F7F9;
            }

            > .body, > .head {
                background: $white;
            }

            & + .open {
                border-top: 1px solid rgba($blackBlue, 0.04);
            }
        }
    }
}
</style>

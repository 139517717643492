<template>
    <div class="HeaderDropdownMenu">
        <HeaderDropdownMenuItem
            :class="{'HeaderDropdownMenu__item-rating': userRating}"
            :key="-1"
            title="Мой профиль"
            :to="{name: 'profile'}"
            :icon="ProfileIcon"
            @click="$signals.headerClickMenuItem"
        >
            <ProfileRatingShort
                v-if="userRating"
                :rating="userRating"
                inline
            />
        </HeaderDropdownMenuItem>
        <HeaderDropdownMenuItem
            v-for="({title, to, icon, notifications}, index) in menu"
            :key="index"
            :title="title"
            :to="to"
            :icon="icon"
            :notifications="notifications"
            @click="$signals.headerClickMenuItem"
        />
        <div class="HeaderDropdownMenu__divider"/>
        <div class="HeaderDropdownMenuItem" @click="logout">
            <span class="HeaderDropdownMenuItem__icon-holder" v-html="LogoutIcon"/>
            <span>Выйти</span>
        </div>
    </div>
</template>

<script>

import ProfileIcon from '@/assets/img/icons/dropdown-header-profile.svg?raw'
import ProfileDocumentsIcon from 'assets/img/icons/profile-documents.svg?raw'
import ReservationsIcon from '@/assets/img/icons/dropdown-header-my-reservations.svg?raw'
import ListIcon from '@/assets/img/icons/dropdown-header-list.svg?raw'
import AddCarIcon from 'assets/img/icons/dropdown-header-add-car.svg?raw'
import LogoutIcon from '@/assets/img/icons/dropdown-header-logout.svg?raw'
import PaymentIcon from '@/assets/img/icons/nav/profile/payment.svg?raw';
import ChatIcon from 'assets/img/icons/nav/tab-bar/chat.svg?raw';

import {ROLES} from '@/utils/_consts';
import {mapState} from "vuex";
import HeaderDropdownMenuItem from "@/components/_layout/header/HeaderDropdownMenuItem";
import ProfileRatingShort from "@/components/nav/profile/ProfileRatingShort";

export default {
    name: "HeaderDropdownMenu",
    components: {ProfileRatingShort, HeaderDropdownMenuItem},
    data: () => ({
        LogoutIcon,
        ProfileIcon,
    }),
    computed: {
        menu() {
            const isOwner = this.$auth.user?.role?.id === ROLES.OWNER;

            return [
                {
                    title: 'Проверка документов',
                    to: {name: 'profile-confirm'},
                    icon: ProfileDocumentsIcon,
                },
                {
                    title: 'Карта для приема оплат',
                    to: {name: 'profile-payment'},
                    icon: PaymentIcon,
                    onlyForOwner: true,
                },
                {
                    title: 'Мои заявки',
                    to: {name: 'profile-reservations'},
                    icon: ListIcon,
                },
                {
                    title: 'Входящие',
                    to: {name: 'profile-chat-dealId'},
                    icon: ChatIcon,
                    notifications: this.profileUnreadMessagesCount,
                },
                {
                    title: 'Мои авто',
                    to: {name: 'profile-my-cars'},
                    icon: ReservationsIcon,
                },
                {
                    title: 'Добавить авто',
                    to: {name: 'profile-wizard-carId-basic'},
                    icon: AddCarIcon,
                },
            ].filter((item) => {
                if (item.onlyForOwner) {
                    return isOwner;
                }
                return true;
            });
        },
        userRating() {
            return this.$auth.user?.renter_rating;
        },
        ...mapState('profile', ['profileUnreadMessagesCount']),
    },
    methods: {
        logout() {
            this.$auth.logout().then(() => {
                this.$router.push('/');
            });
        },
    },
}
</script>

<style lang="scss">
.HeaderDropdownMenu {
    padding: 6px 0;

    background: $white;
    box-shadow: 0 1px 1px rgba(11, 33, 65, 0.02), 0 6px 120px rgba(11, 33, 65, 0.1);
    border-radius: 8px;

    &::before {
        content: '';
        background-image: url("~@/assets/img/icons/header-dropdown-arrow.svg");
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        bottom: calc(100% - 1px);
        right: 40px;
        width: 32px;
        height: 6px;
    }

    @include mobile {
        position: relative;
        margin-top: 6px;
        margin-bottom: 12px;
    }

    @include mobileUp {
        min-width: 288px;
        position: absolute;
        top: calc(100% + 4px);
        right: 0;
        z-index: 10;
    }

    &__item-rating {
        height: 58px;
        align-items: flex-start;
        padding-top: 10px;

        .ProfileRatingShort {
            position: absolute;
            top: 100%;
            left: 0;
        }
    }

    .HeaderDropdownMenu__divider {
        margin: 6px 16px;
        border-bottom: 1px solid rgba($blackBlue, 0.04);
    }
}
</style>

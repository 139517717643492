<template>
    <div
        class="ProfileRatingShort"
        :class="{'ProfileRatingShort--inline': inline}"
    >
        <div class="ProfileRatingShort__label">
            {{ label }}
        </div>
        <RatingLine
            :height="12"
            :rating="rating"
        />
    </div>
</template>

<script>
import RatingLine from "@/components/cars/show/reviews/ratingLine";

export default {
    name: "ProfileRatingShort",
    components: {RatingLine},
    props: {
        rating: Number,
        inline: Boolean,
    },
    computed: {
        label() {
            const rating = (+this.rating || 0).toFixed(1);
            return `Ваш рейтинг ${rating}`;
        },
    },
}
</script>

<style lang="scss">
.ProfileRatingShort {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;

    &--inline {
        flex-direction: row;
        align-items: center;

        .ProfileRatingShort__label {
            margin-right: 7px;
        }
    }

    &__label {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: rgba($blackBlue, 0.64);
    }

    .rating-line {
        grid-gap: 4px;
    }
}
</style>

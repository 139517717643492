<template>
    <div
        class="PaymentBonusPointsCount"
        :class="{'PaymentBonusPointsCount--compact': compact}"
    >
        <div class="PaymentBonusPointsCount__icon">
            <BonusIcon/>
        </div>
        <div class="PaymentBonusPointsCount__count">
            {{ profilePaymentBonusPoints }}
        </div>
    </div>
</template>

<script>
import BonusIcon from '@/assets/img/icons/payment-bonus-point.svg?inline'
import profilePaymentBonusPoints from "@/mixins/profile/profilePaymentBonusPoints";

export default {
    name: "PaymentBonusPointsCount",
    components: {
        BonusIcon,
    },
    mixins: [profilePaymentBonusPoints],
    props: {
        compact: Boolean,
    },
}
</script>

<style lang="scss">
.PaymentBonusPointsCount {
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;

    padding: 6px 10px;

    background: #EBF2FF;
    border-radius: 8px;

    &--compact {
        padding: 4px 10px;
    }

    &__icon {
        display: flex;
        margin-right: 7px;
    }

    &__count {
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: -0.005em;
        color: #307EFD;
    }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-nav"},[_c('div',{staticClass:"main-content"},[_c('ul',{staticClass:"nav-list"},[_vm._l((_vm.catalogNavItems),function(ref){
var name = ref.name;
var filters = ref.filters;
return _c('li',{key:name,staticClass:"nav-item",on:{"click":function($event){return _vm.$signals.headerClickNavItem(name)}}},[_c('router-to-cars',{staticClass:"nav-link",attrs:{"filters":filters}},[_vm._v("\n                    "+_vm._s(name)+"\n                ")])],1)}),_vm._v(" "),_vm._l((_vm.menu),function(ref){
var title = ref.title;
var to = ref.to;
var source_name = ref.source_name;
return _c('li',{key:title,staticClass:"nav-item",on:{"click":function($event){return _vm.headerClickNavItem(title, source_name)}}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":to}},[_vm._v(_vm._s(title))])],1)}),_vm._v(" "),(!_vm.authorized)?_c('li',{staticClass:"nav-item hide-pc",on:{"click":_vm.$signals.headerClickSignIn}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/sign-in"}},[_vm._v("Войти")])],1):_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"other-actions"},[_c('header-phone-number'),_vm._v(" "),_c('location-select')],1)]),_vm._v(" "),_c('div',{staticClass:"other-actions-block"},[_c('div',{staticClass:"phone-number-pc"},[_c('header-phone-number')],1),_vm._v(" "),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeBonusPointsToolTip),expression:"closeBonusPointsToolTip"}],staticClass:"payment-bonus-block",class:{'expiring-bonus': _vm.profileExpiringBonusPoints.length},on:{"mouseenter":_vm.openBonusPointsToolTip,"mouseleave":_vm.handleBonusPointsMouserLeave}},[(_vm.$auth.loggedIn)?_c('PaymentBonusPointsCount'):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showBonusPointsTooltip),expression:"showBonusPointsTooltip"}],staticClass:"payment-bonus-tooltip"},[_c('b',{staticClass:"payment-bonus-tooltip-title"},[_vm._v("Накопленные баллы")]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"payment-bonus-tooltip-link",on:{"click":_vm.openModalBonusPointsInfoModal}},[_vm._v("\n                    Подробнее\n                ")]),_vm._v(" "),_c('PaymentExpiringBonusPoints',{staticClass:"header-nav-expiring-bonuses",attrs:{"dark":""}})],1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v("\n                    Баллы можно тратить на будущие "),_c('br'),_vm._v("\n                    поездки, 1 балл = 1 рубль.\n                ")])}]

export { render, staticRenderFns }